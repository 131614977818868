<template>
  <keep-alive :exclude="excludename">
    <!-- 主应用路由渲染出口 -->
    <router-view v-if="isRouterAlive"></router-view>
  </keep-alive>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
const excludename = ''

export default {
  Name: 'Home',
  computed: {
    ...mapState(['exclude', 'userSession']),
  },
  data() {
    return {
      excludename,
      collapsed: false,
      isRouterAlive: true,
      ready: false,
      guideBarShow: false,
      currentSession: null,
      defaultPercent: 0,
      timer: null,
      showIcon: true,
    }
  },
  created() {
    this.getSourceMap()
    this.getCurrencyList()
    this.getcurrencyRateList()
  },
  methods: {
    ...mapMutations(['setState']),
    changeCollapsed() {
      this.collapsed = !this.collapsed
    },
    reload(newVal) {
      this.isRouterAlive = false
      this.excludename = newVal
      this.$nextTick(() => {
        this.excludename = excludename
        this.setState({
          exclude: null,
        })
        this.isRouterAlive = true
      })
    },
    topBarReady() {
      this.ready = true
    },
    topBarUnready() {
      this.ready = false
    },
    async getSourceMap() {
      try {
        http({
          url: api.getAssetBaseInfoMap,
          success: (res) => {
            let select_map = res.result.select_map
            if (select_map && typeof select_map === 'object') {
              // 创建一个新对象来存储所有的数据
              let newState = {}
              for (let item in select_map) {
                newState[item] = select_map[item]
              }
              // 只调用一次 setState
              this.setState(newState)
            }
          },
        })
        let res = await http({
          url: api.getMappingList,
          type: 'post',
          data: {},
        })
        let select_map = res.result.select_map
        if (select_map && typeof select_map === 'object') {
          // 创建一个新对象来存储所有的数据
          let newState = {}
          for (let item in select_map) {
            newState[item] = select_map[item]
          }
          // 只调用一次 setState
          this.setState(newState)
        }
      } catch (error) {
        console.error('获取类型列表失败:', error)
      }
    },
    async getCurrencyList() {
      if (this.$store.state.currencyList.length > 0) return
      let res = await http({
        url: api.getCurrencyList,
        type: 'post',
        data: {
          isEnabled: 1,
        },
      })
      let list = (res.result.list || []).map((item) => ({
        ...item,
        name: item.currency_name,
        code: item.currency_code,
      }))
      this.setState({
        currencyList: list,
      })
    },
    async getcurrencyRateList() {
      if (this.$store.state.currencyRateMapping.length > 0) return
      let res = await http({
        url: api.getcurrencyRateList,
        type: 'post',
        data: {
          isEnabled: 1,
        },
      })
      let currencyRateMapping = {}
      res.result.list.forEach((item) => {
        currencyRateMapping[item.original_currency_code] = item.indirect_exchange_rate
      })
      this.setState({
        currencyRateMapping,
      })
    },
  },
  watch: {
    exclude(newVal) {
      if (newVal) {
        this.reload(newVal)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#components-layout-demo-custom-trigger {
  height: 100%;
}

.ant-layout-sider-collapsed {
  flex: 0 0 80px;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}

.ant-layout-content {
  overflow-y: auto;
  scroll-behavior: smooth;
}

.spin-main {
  height: 300px;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.csdn-side-toolbar {
  position: fixed;
  right: 24px;
  bottom: 120px;
  width: 44px;
  z-index: 998 !important;
  .close-icon {
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 999;
    cursor: pointer;
    display: none;
  }
  a.option-box {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    background: #000;
    -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
    background-color: $white;
    text-align: center;
    height: 44px;
    cursor: pointer;
    margin-top: 8px;
    &:hover .show-txt {
      display: block;
      opacity: 1;
    }
    &:hover .img {
      opacity: 0;
    }
    img {
      display: block;
      width: 24px;
    }
    .show-txt {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-size: 12px;
      line-height: 44px;
      color: #999aaa;
      background-color: $white;
      text-align: center;
      opacity: 1;
      -webkit-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out;
    }
  }

  &:hover {
    .close-icon {
      display: block;
    }
  }
}
::v-deep .ant-layout-sider-children .sideMenu {
  max-height: calc(100% - 69px) !important;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track-piece {
    background: white;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1c1c2;
  }
}
</style>
